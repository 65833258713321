import React from "react"
import sortBy from "lodash/sortBy"

const manualEntries = [
  {
    meetupName: "Wemake",
    url:
      "https://www.fastwebdigital.academy/formazione-giovani/digital-design-making/smart-things-oggetti-programmabili",
    date: 1574031600000,
    dateTo: 1574377200000,
    title: 'Workshop: "IOT: oggetti programmabili e connessi"',
    venue: "Fablab Wemake",
  },
].filter(e => {
  const now = new Date()
  if (e.dateTo && now > e.dateTo) {
    return false
  }
  if (!e.dateTo && now > e.date) {
    return false
  }

  return true
})

export default function EventList({ events }) {
  const s = sortBy([...events, ...manualEntries], "date")

  return (
    <div>
      {s.map(e => {
        return (
          <div
            key={e.title + e.date}
            css={{
              position: "relative",
              margin: "80px 0",
              "@media(min-width: 769px)": {
                margin: "112px 0",
              },
            }}
          >
            <aside
              css={{
                "@media(min-width: 769px)": {
                  width: 420,
                  position: "absolute",
                  color: "var(--light-text)",
                  left: -560,
                  transition: "color 0.25s",
                  textAlign: "right",
                },
              }}
            >
              <h4 css={{ margin: 0 }}>
                {e.dateTo && e.dateTo !== "null" && <small>da</small>}{" "}
                {e.date && e.date !== "null" && formatDate(Number(e.date))}
                {e.dateTo && e.dateTo !== "null" && (
                  <div css={{ marginTop: 4 }}>
                    <small>a</small> {formatDate(Number(e.dateTo))}
                  </div>
                )}
              </h4>
              <div css={{ fontWeight: "lighter" }}>{e.venue}</div>
            </aside>

            <div css={{ width: "100%", maxWidth: "420px" }}>
              <h3
                css={{
                  fontSize: 24,
                  margin: 0,
                  ineHeight: 1.1,
                  fontWeight: "900",
                }}
              >
                <a
                  css={{ textDecoration: "none", color: "inherit" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={e.url}
                >
                  {e.title}
                </a>
              </h3>

              <div css={{ fontWeight: "lighter" }}>{e.meetupName}</div>
            </div>
            {/* {e.imageUrl ? (
              <img style={{ maxWidth: 200 }} src={e.imageUrl} alt="" />
            ) : null} */}
          </div>
        )
      })}
    </div>
  )
}

function formatDate(date) {
  const d = new Date(date)

  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)

  const isToday = d.toDateString() === today.toDateString()
  const isTomorrow = d.toDateString() === tomorrow.toDateString()

  const str = new Intl.DateTimeFormat("it-IT", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  })
    .format(d)
    .toString()
  const relativeString = isToday ? " (oggi)" : isTomorrow ? " (domani)" : ""
  return `${str} ${relativeString}`
}
