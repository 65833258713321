import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SideLayout from "../components/Sidelayout"
import EventList from "../components/EventList"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allEventsJson {
        edges {
          node {
            id
            meetupName
            url
            when
            date
            title
            venue
          }
        }
      }
    }
  `)

  return (
    <SideLayout>
      <SEO title="Home" />
      <div>
        <div
          css={{
            background: "white",
            position: "relative",
            border: "4px solid var(--dark-bg)",
            borderRadius: 4,
            padding: 8,
            textAlign: "center",
            "@media (min-width: 769px)": {
              marginLeft: -60,
              left: "-50%",
            },
          }}
        >
          <h2 css={{ margin: 0, fontSize: 24 }}>Prossimi Eventi a Milano</h2>
        </div>
        <EventList events={data.allEventsJson.edges.map(n => n.node)} />
      </div>
    </SideLayout>
  )
}

export default IndexPage
